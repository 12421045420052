// IMPORTANT: Anything statically imported here will be included
// in all code paths because we currently use a single route definitions file.
// Therefore, it is important to be mindful of what is imported _and_ what
// those imports import. For instance, the AuthRoute, ProtectedRoute, etc...
// end up importing AuthContext and Nav components, which end up importing
// a bunch of stuff also. This can lead to a lot of unnecessary code being
// bundled for every route in the app. Tree-shaking/dead code elimination may
// be able to help at bundle time, but we shouldn't rely on it.
// We'll be working on de-tangling downstream dependencies in future efforts,
// but this comment is here to serve as a reminder.

import React, { lazy } from 'react';

import { DefaultLayout } from './components/layouts/default';
import { FullPageLayoutRoute } from './components/layouts/full-page/full-page-layout-route';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from './services/router/service';

// LAYOUTS AND RENDERERS
const AppEntryPoint = lazy(
  () =>
    import(
      /* webpackChunkName: "entry.app" */
      '@/app'
    )
);

const FullPageLayout = lazy(
  () =>
    import(
      /* webpackChunkName: "layout.full-page" */ '@/components/layouts/full-page/full-page-layout'
    )
);

const AppPageRenderer = lazy(
  () =>
    import(
      /* webpackChunkName: "renderer.app-page-renderer" */
      '@/service-containers/app/app-page-renderer'
    )
);

const WebviewCreditCardDepositsEntryPoint = lazy(
  () =>
    import(
      /* webpackChunkName: "entry.webview-credit-card-deposits" */
      '@/service-containers/webviews/credit-card-deposit/entry-point'
    )
);

const WebviewResponsibleGamingLimitsEntryPoint = lazy(
  () =>
    import(
      /* webpackChunkName: "entry.webview-responsible-gaming-limits" */
      '@/service-containers/webviews/responsible-gaming-limits/entry-point'
    )
);

const WebviewTransactionsEntryPoint = lazy(
  () =>
    import(
      /* webpackChunkName: "entry.webview-transactions" */
      '@/service-containers/webviews/transactions/entry-point'
    )
);

const WebviewTransactionsPageRenderer = lazy(
  () =>
    import(
      /* webpackChunkName: "renderer.webview-transactions" */
      '@/service-containers/webviews/transactions/webview-transactions-page-renderer'
    )
);

// ROUTE WRAPPERS
const AuthRoute = lazy(
  () =>
    import(
      /* webpackChunkName: "wrapper.auth-route" */ './components/organisms/route-wrappers/auth-route'
    )
);

const ProtectedMobileRoute = lazy(
  () =>
    import(
      /* webpackChunkName: "wrapper.protected-mobile-route" */ './components/organisms/route-wrappers/protected-mobile-route'
    )
);

const ProtectedRoute = lazy(
  () =>
    import(
      /* webpackChunkName: "wrapper.protected-route" */ './components/organisms/route-wrappers/protected-route'
    )
);

// PAGES
const AccountPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.account" */
      '@/components/pages/account'
    )
);
const AccountUnblockedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.account-unblocked" */
      '@/components/pages/account-unblocked'
    )
);
const AccountUnlockPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.account-unlock" */
      '@/components/pages/account-unlock'
    )
);
const ActivePage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.active" */
      '@/components/pages/active'
    )
);
const AndroidDownloadPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.android-download" */
      '@/components/pages/android-download'
    )
);
const AverageResultsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.average-results" */
      '@/components/pages/average-results'
    )
);
const CareersPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.careers" */
      '@/components/pages/careers'
    )
);
const CareersFaqsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.careers-faqs" */
      '@/components/pages/careers-faqs'
    )
);
const ContentfulPromoInfo = lazy(
  () =>
    import(
      /* webpackChunkName: "page.contentful-promo-info" */
      '@/components/molecules/contentful-promo-info'
    )
);
const ContestLegalityPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.contest-legality" */
      '@/components/pages/contest-legality'
    )
);
const CompletedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.completed" */
      '@/components/pages/completed'
    )
);
const DashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.dashboard" */
      '@/components/pages/dashboard'
    )
);
const ToTheUnderdogsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.to-the-underdogs" */
      '@/components/pages/to-the-underdogs'
    )
);
const DepositPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.deposit" */
      '@/components/pages/deposit'
    )
);
const DepositSuccessPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.deposit-success" */
      '@/components/pages/deposit-success'
    )
);
const DraftPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.draft" */
      '@/components/pages/draft'
    )
);
const DraftBoardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.draft-board" */
      '@/components/pages/draft-board'
    )
);
const DownloadTheAppPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.download-the-app" */
      '@/components/pages/download-the-app'
    )
);
const DownloadPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.download" */
      '@/components/pages/download'
    )
);
const EmailVerifiedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.email-verified" */
      '@/components/pages/email-verified'
    )
);
const LoginConfirmationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.login-confirmation" */
      '@/components/pages/login-confirmation'
    )
);
const ForgotPasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.forgot-password" */
      '@/components/pages/forgot-password'
    )
);
const ChangedPasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.password-changed" */
      '@/components/pages/password-changed'
    )
);
const GuardDogPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.guard-dog" */
      '@/components/pages/guard-dog'
    )
);
const LandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.landing" */
      '@/components/pages/landing'
    )
);
const LivePage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.live" */
      '@/components/pages/live'
    )
);
const LoginPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.login" */
      '@/components/pages/login'
    )
);
const ExposurePage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.exposure" */
      '@/components/pages/exposure'
    )
);
const LobbyPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.lobby" */
      '@/components/pages/lobby'
    )
);
const NewsFeedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.news-feed" */
      '@/components/pages/news-feed'
    )
);
const PickEmPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.pick-em" */
      '@/components/pages/pick-em'
    )
);
const PromosPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.promos" */
      '@/components/pages/promos'
    )
);
const RankingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.rankings" */
      '@/components/pages/rankings'
    )
);
const RegisterPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.register" */
      '@/components/pages/register'
    )
);
const ResetCachePage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.reset-cache" */
      '@/components/pages/reset-cache'
    )
);
const ResponsibleGamingResourcesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.responsible-gaming-resources" */
      '@/components/pages/responsible-gaming-resources'
    )
);
const ResultsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.results" */
      '@/components/pages/results'
    )
);
const RulesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.rules" */
      '@/components/pages/rules'
    )
);
const RulesRoutes = lazy(
  () =>
    import(
      /* webpackChunkName: "page.rules" */
      '@/components/pages/rules/components/rules-routes'
    )
);
const SplashPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.splash" */
      '@/components/pages/splash'
    )
);
const SweepstakeTermsAndConditionsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.sweepstake-terms-and-conditions" */
      '@/components/pages/sweepstake-terms-and-conditions'
    )
);
const TaxPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.tax" */
      '@/components/pages/tax'
    )
);
const TransactionPageWebview = lazy(
  () =>
    import(
      /* webpackChunkName: "page.transaction-page-webview" */
      '@/components/pages/webviews/transaction'
    )
);
const TransactionsPageWebview = lazy(
  () =>
    import(
      /* webpackChunkName: "page.transactions-page-webview" */
      '@/components/pages/webviews/transactions'
    )
);
const TrustlyCancelDepositPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.trustly-cancel-deposit" */
      '@/components/pages/trustly-cancel-deposit'
    )
);
const TrustlyLoadingDepositPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.trustly-loading-deposit" */
      '@/components/pages/trustly-loading-deposit'
    )
);
const UnknownRoutePage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.unknown-route" */
      '@/components/pages/unknown-route'
    )
);
const VerificationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.verification" */
      '@/components/pages/verification'
    )
);
const WithdrawalConfirmationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page.withdrawal-confirmation" */
      '@/components/pages/withdrawal-confirmation'
    )
);

// note: eventually, we can start breaking apart the route tree into
// smaller pieces in order to further define boundaries between different
// parts of the app. This will help with optimizing for webviews / non-webviews.
const routeTable = createRoutesFromElements(
  <Route element={<Outlet />}>
    <Route element={<WebviewTransactionsEntryPoint />}>
      <Route
        path="/m/transaction-history"
        element={
          <WebviewTransactionsPageRenderer
            withServices={['store']}
            renderer={({ services }) => {
              return <TransactionsPageWebview store={services.store} />;
            }}
          />
        }
      />
      <Route path="/m/transaction-history/:transactionId" element={<TransactionPageWebview />} />
    </Route>
    <Route path="/m/credit-card-deposit" element={<WebviewCreditCardDepositsEntryPoint />} />
    <Route
      path="/m/responsible-gaming-limits/*"
      element={<WebviewResponsibleGamingLimitsEntryPoint />}
    />
    <Route element={<AppEntryPoint />}>
      <Route element={<DefaultLayout />}>
        <Route
          path="/account-unblocked"
          element={
            <AuthRoute>
              <AccountUnblockedPage />
            </AuthRoute>
          }
        />
        <Route path="/account-unlock" element={<AccountUnlockPage />} />
        <Route path="/android-download" element={<AndroidDownloadPage />} />
        <Route path="/average-results" element={<AverageResultsPage />} />
        <Route path="/m/average-results" element={<AverageResultsPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/m/careers" element={<CareersPage />} />
        <Route path="/careers-faqs" element={<CareersFaqsPage />} />
        <Route path="/m/careers-faqs" element={<CareersFaqsPage />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/download-the-app" element={<DownloadTheAppPage />} />
        <Route path="/to-the-underdogs" element={<ToTheUnderdogsPage />} />
        <Route path="/legality-underdog-contests" element={<ContestLegalityPage />} />
        <Route path="/m/guarddog" element={<GuardDogPage />} />
        <Route path="/guarddog" element={<GuardDogPage />} />
        <Route path="/email-verified" element={<EmailVerifiedPage />} />
        <Route path="/withdrawal-confirmation" element={<WithdrawalConfirmationPage />} />
        {/* TODO: remove this after mobile sets up contentful */}
        <Route
          path="/m/promo-info/:contentfulInfoId"
          element={<ContentfulPromoInfo contentfulInfoId="" handleCloseModal={null} />}
        />
        <Route path="/rules/*" element={<RulesPage />}>
          <Route path="*" element={<RulesRoutes />} />
        </Route>
        <Route path="/m/rules/*" element={<RulesPage />}>
          <Route path="*" element={<RulesRoutes />} />
        </Route>
        <Route path="/splash/*" element={<SplashPage />} />

        <Route path="/reset-cache" element={<ResetCachePage />} />
        <Route path="/terms-and-conditions/:slug" element={<SweepstakeTermsAndConditionsPage />} />
        {/* Registration and login routes */}
        <Route
          path="/"
          element={
            <AuthRoute>
              <LandingPage />
            </AuthRoute>
          }
        />
        <Route
          path="/promos"
          element={
            <AuthRoute>
              <PromosPage />
            </AuthRoute>
          }
        />
        <Route
          path="/register"
          element={
            <AuthRoute>
              <RegisterPage />
            </AuthRoute>
          }
        />
        <Route
          path="/login"
          element={
            <AuthRoute>
              <LoginPage />
            </AuthRoute>
          }
        />
        <Route path="/password-changed" element={<ChangedPasswordPage />} />
        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <ForgotPasswordPage />
            </AuthRoute>
          }
        />
        <Route
          path="/login-confirmation"
          element={
            <AuthRoute>
              <LoginConfirmationPage />
            </AuthRoute>
          }
        />
        {/* Protected routes where you have to be logged in */}
        <Route
          path="/account/*"
          element={
            <ProtectedRoute>
              <AccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/active"
          element={
            <ProtectedRoute>
              <AppPageRenderer
                withServices={['braze']}
                renderer={({ services }) => {
                  return <ActivePage brazeService={services.braze} />;
                }}
              />
            </ProtectedRoute>
          }
        >
          <Route
            path=":draftId"
            element={
              <ProtectedRoute>
                <AppPageRenderer
                  withServices={['braze']}
                  renderer={({ services }) => {
                    return <ActivePage brazeService={services.braze} />;
                  }}
                />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deposit"
          element={
            <ProtectedRoute>
              <AppPageRenderer
                withServices={['paysafe', 'pciProxy']}
                renderer={({ services }) => {
                  return (
                    <DepositPage
                      paysafeService={services.paysafe}
                      pciProxyService={services.pciProxy}
                    />
                  );
                }}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deposit-success/:amount"
          element={
            <ProtectedRoute>
              <DepositSuccessPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/draft"
          element={
            <ProtectedRoute>
              <AppPageRenderer
                withServices={['braze']}
                renderer={({ services }) => {
                  return <DraftPage brazeService={services.braze} />;
                }}
              />
            </ProtectedRoute>
          }
        >
          <Route
            path=":draftId"
            element={
              <ProtectedRoute>
                <AppPageRenderer
                  withServices={['braze']}
                  renderer={({ services }) => {
                    return <DraftPage brazeService={services.braze} />;
                  }}
                />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/draft-board/:draftId"
          element={
            <ProtectedRoute hideNav>
              <DraftBoardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/completed"
          element={
            <ProtectedRoute>
              <CompletedPage />
            </ProtectedRoute>
          }
        >
          <Route
            path=":slateId"
            element={
              <ProtectedRoute>
                <CompletedPage />
              </ProtectedRoute>
            }
          >
            {/* draftType is (sng|tr|br) */}
            <Route
              path=":draftType"
              element={
                <ProtectedRoute>
                  <CompletedPage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":selectedId"
                element={
                  <ProtectedRoute>
                    <CompletedPage />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="/live"
          element={
            <ProtectedRoute>
              <LivePage />
            </ProtectedRoute>
          }
        >
          {/* gameType is (daily|best-ball|pick-em) */}
          <Route
            path=":gameType"
            element={
              <ProtectedRoute>
                <LivePage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":sportId"
              element={
                <ProtectedRoute>
                  <LivePage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":slateId"
                element={
                  <ProtectedRoute>
                    <LivePage />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="/exposure"
          element={
            <ProtectedRoute>
              <ExposurePage />
            </ProtectedRoute>
          }
        >
          <Route
            path=":slateId"
            element={
              <ProtectedRoute>
                <ExposurePage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":contestStyleId"
              element={
                <ProtectedRoute>
                  <ExposurePage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route
          path="/lobby"
          element={
            <ProtectedRoute>
              <LobbyPage />
            </ProtectedRoute>
          }
        >
          <Route
            path=":sportId"
            element={
              <ProtectedRoute>
                <LobbyPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":slateId"
              element={
                <ProtectedRoute>
                  <LobbyPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route
          path="/news-feed"
          element={
            <ProtectedRoute>
              <NewsFeedPage />
            </ProtectedRoute>
          }
        >
          <Route
            path=":sportId"
            element={
              <ProtectedRoute>
                <NewsFeedPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/pick-em"
          element={
            <ProtectedRoute>
              <PickEmPage />
            </ProtectedRoute>
          }
        >
          {/* pageType is (higher-lower|rivals|favorites|search) react-router-dom won't let us specify this */}
          <Route
            path=":pageType"
            element={
              <ProtectedRoute>
                <PickEmPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":pickTiming"
              element={
                <ProtectedRoute>
                  <PickEmPage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":sportId"
                element={
                  <ProtectedRoute>
                    <PickEmPage />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="/rankings"
          element={
            <ProtectedRoute>
              <RankingsPage />
            </ProtectedRoute>
          }
        >
          <Route
            path=":sportId"
            element={
              <ProtectedRoute>
                <RankingsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":slateId"
              element={
                <ProtectedRoute>
                  <RankingsPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route
          path="/results"
          element={
            <ProtectedRoute>
              <ResultsPage />
            </ProtectedRoute>
          }
        >
          {/* gameType is (drafts|pick-em) */}
          <Route
            path=":gameType"
            element={
              <ProtectedRoute>
                <ResultsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":sportId"
              element={
                <ProtectedRoute>
                  <ResultsPage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":slateId"
                element={
                  <ProtectedRoute>
                    <ResultsPage />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route
          path="/trustly-cancel-deposit"
          element={
            <ProtectedRoute>
              <TrustlyCancelDepositPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="trustly-loading-deposit"
          element={
            <ProtectedRoute>
              <TrustlyLoadingDepositPage />
            </ProtectedRoute>
          }
        />

        {/* Mobile routes */}
        <Route
          path="/m/deposit"
          element={
            <ProtectedMobileRoute>
              <AppPageRenderer
                withServices={['paysafe', 'pciProxy']}
                renderer={({ services }) => {
                  return (
                    <DepositPage
                      paysafeService={services.paysafe}
                      pciProxyService={services.pciProxy}
                    />
                  );
                }}
              />
            </ProtectedMobileRoute>
          }
        />
        <Route
          path="/m/deposit-success/:amount"
          element={
            <ProtectedMobileRoute>
              <DepositSuccessPage />
            </ProtectedMobileRoute>
          }
        />
        <Route
          path="/m/news-feed/:sportId"
          element={
            <ProtectedMobileRoute>
              <NewsFeedPage />
            </ProtectedMobileRoute>
          }
        />
      </Route>

      <Route element={<FullPageLayout />}>
        <FullPageLayoutRoute
          path="/responsible-gaming-resources"
          handle={{
            layoutProps: {
              hasNav: true,
              hasFooter: true,
              layout: 'wide',
            },
          }}
          element={<ResponsibleGamingResourcesPage />}
        />
        <FullPageLayoutRoute
          path="/m/responsible-gaming-resources"
          handle={{
            layoutProps: {
              hasFooter: true,
              layout: 'wide',
            },
          }}
          element={<ResponsibleGamingResourcesPage />}
        />
        <FullPageLayoutRoute
          path="/tax"
          handle={{
            layoutProps: {
              layout: 'narrow',
              hasNav: true,
            },
          }}
          element={
            <ProtectedRoute hideNav>
              <TaxPage />
            </ProtectedRoute>
          }
        />
        <FullPageLayoutRoute
          path="/verification/docv?"
          handle={{
            layoutProps: {
              layout: 'narrow',
              hasNav: true,
            },
          }}
          element={
            <ProtectedRoute hideNav>
              <VerificationPage />
            </ProtectedRoute>
          }
        />
        <FullPageLayoutRoute
          path="/m/tax"
          handle={{
            layoutProps: {
              layout: 'narrow',
            },
          }}
          element={
            <ProtectedMobileRoute>
              <TaxPage />
            </ProtectedMobileRoute>
          }
        />
        <FullPageLayoutRoute
          path="/m/verification"
          handle={{
            layoutProps: {
              layout: 'narrow',
            },
          }}
          element={
            <ProtectedMobileRoute>
              <VerificationPage />
            </ProtectedMobileRoute>
          }
        />
      </Route>
      {/* Redirect unknown routes to the lobby */}
      <Route path="*" element={<UnknownRoutePage />} />
    </Route>
  </Route>
);

export const rootRouter = createBrowserRouter(routeTable);
